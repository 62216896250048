import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneResult,
  DeleteParams,
  Record,
  DeleteResult,
} from "react-admin";
import { API_URL } from "./http/index";

const apiUrl = `${API_URL}/api/v1/admin`;

const compareObjects = (oldObj: any, newObj: any) => {
  let attributes = [];
  let values = [];

  for (const key in oldObj) {
    if (oldObj[key] !== newObj[key]) {
      attributes.push(key);
      values.push(newObj[key]);
    }
  }
  return { attributes, values };
};

const getQueryString = (queries: any) => {
  return Object.keys(queries)
    .reduce((result: any, key: any) => {
      return [
        ...result,
        `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`,
      ];
    }, [])
    .join("&");
};

const dataProviderRefactored: DataProvider = {
  getList: (
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> => {
    let res: { token: string } = JSON.parse(
      localStorage.getItem("auth") ?? "{}"
    );
    let url = "";
    const options: any = {
      headers: new Headers({
        Accept: "application/json",
        Authorization: `Bearer ${res.token}`,
      }),
    };

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    url = `${apiUrl}/${resource}/all?${getQueryString(
      params.filter
    )}&${getQueryString(params.sort)}&${getQueryString(params.pagination)}`;

    let headers: Headers;

    return fetch(url, options)
      .then((res) => {
        headers = res.headers;
        return res.json();
      })
      .then((json: any) => {
        const result: { data: any; total: any } = {
          data: [],
          total: 0,
        };

        if (resource === "payroll") {
          result.data = json.payrolls;
          result.total = json.total;
        }
        if (resource === "advance") {
          result.data = json.advances;
          result.total = json.total;
        }
        if (resource === "user") {
          result.data = json.accounts;
          result.total = json.total;
        }

        return result;
      });
  },

  getOne: (resource, params) => {
    let res: { token: string } = JSON.parse(
      localStorage.getItem("auth") ?? "{}"
    );
    let url = "";
    const options: any = {
      headers: new Headers({
        Accept: "application/json",
        Authorization: `Bearer ${res.token}`,
      }),
    };

    let headers: Headers;

    url = `${apiUrl}/${resource}/${params.id}`;
    return fetch(url, options)
      .then((res) => {
        headers = res.headers;
        return res.json();
      })
      .then((json: any) => {
        const result: { data: any; total: any } = { data: [], total: 0 };
        if (resource === "payroll") {
          result.data = json.payroll[0];
        }
        if (resource === "advance") {
          result.data = json.advance[0];
        }
        if (resource === "user") {
          result.data = json.account[0];
        }

        return result;
      });
  },

  getMany: (resource, params) => new Promise((resolve, reject) => {}),

  getManyReference: (resource, params) => new Promise((resolve, reject) => {}),

  create: (resource, params) => {
    let res: { token: string } = JSON.parse(
      localStorage.getItem("auth") ?? "{}"
    );
    let url = "";
    const options: any = {
      headers: new Headers({
        Accept: "application/json",
        Authorization: `Bearer ${res.token}`,
      }),
      method: "POST",
      body: JSON.stringify(params.data),
    };

    let headers: Headers;

    url = `${apiUrl}/${resource}`;
    return fetch(url, options)
      .then((res) => {
        headers = res.headers;
        return res.json();
      })
      .then((json: any) => {
        const result: { data: any } = { data: [] };
        if (resource === "payroll") {
          result.data = json.payroll;
        }
        if (resource === "advance") {
          result.data = json.advance;
        }
        if (resource === "user") {
          result.data = json.account;
        }

        return result;
      });
  },

  update: (resource, params) => {
    let res: { token: string } = JSON.parse(
      localStorage.getItem("auth") ?? "{}"
    );
    let url = "";
    const body = compareObjects(params.previousData, params.data);
    const options: any = {
      headers: new Headers({
        Accept: "application/json",
        Authorization: `Bearer ${res.token}`,
      }),
      method: "POST",
      body: JSON.stringify(body),
    };

    let headers: Headers;

    url = `${apiUrl}/${resource}/${params.id}`;

    return fetch(url, options)
      .then((res) => {
        headers = res.headers;
        return res.json();
      })
      .then((json: any) => {
        const result: { data: any } = { data: [] };
        if (resource === "payroll") {
          result.data = json.payroll;
          result.data.id = params.id;
        }
        if (resource === "advance") {
          result.data = json.advance;
          result.data.id = params.id;
        }
        if (resource === "user") {
          result.data = json.account;
          result.data.id = params.id;
        }

        return result;
      });
  },

  updateMany: (resource, params) => new Promise((resolve, reject) => {}),

  delete: (resource: string, params: DeleteParams) => {
    let res: { token: string } = JSON.parse(
      localStorage.getItem("auth") ?? "{}"
    );
    let url = `${apiUrl}/${resource}/${params.id}`;
    const options: any = {
      headers: new Headers({
        Accept: "application/json",
        Authorization: `Bearer ${res.token}`,
      }),
      method: "DELETE",
    };

    let headers: Headers;

    return fetch(url, options)
      .then((res) => {
        headers = res.headers;
        return res.json();
      })
      .then((json) => {
        return { data: json };
      });
  },

  deleteMany: (resource, params) => new Promise((resolve, reject) => {}),
};

export default dataProviderRefactored;
