import { AuthProvider } from "react-admin";
import { API_URL } from "./http/index";

const authProvider: AuthProvider = {
  login: ({ username, password }: { username: string; password: string }) => {
    const request = new Request(`${API_URL}/api/v1/user/login`, {
      method: "PUT",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  checkError: ({ status }: { status: number }) => {
    return status === 401 || status === 403
      ? Promise.reject()
      : Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return Promise.resolve(role);
  },
  // getIdentity: () => {
  //   return Promise.resolve({
  //     id: localStorage.getItem("login"),
  //     fullName: localStorage.getItem("user"),
  //     avatar: localStorage.getItem("avatar"),
  //   });
  // },
};

export default authProvider;
