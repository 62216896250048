import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  NumberField,
  EditButton,
  TextInput,
  DateInput,
  Title,
  EditActions,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  NumberInput,
} from "react-admin";

import BookIcon from "@material-ui/icons/Book";

export const PostIcon = BookIcon;

export const PayrollList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="phoneNumber" label="User Identifier" />
        <TextField source="clientNote" />
        <TextField source="rule" />
        <NumberField source="payroll" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const UserEditToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} onClick={() => {}} />
      <DeleteWithConfirmButton onClick={() => {}} onFailure={(error) => {}} />
    </Toolbar>
  );
};

const UserEditForm = (props: any) => {
  const newSave = (values: any) => props.save(values);

  return (
    <SimpleForm
      defaultValue={{ role: "user" }}
      toolbar={<UserEditToolbar />}
      {...props}
      save={newSave}
    >
      <NumberInput source="id" validate={required()} disabled />
      <TextInput source="phoneNumber" validate={required()} label="User Identifier" />
      <TextInput source="clientNote" />
      <TextInput source="rule" />
      <NumberInput source="payroll" locales="en-GB" />
    </SimpleForm>
  );
};

export const PayrollEdit = (props: any) => {
  return (
    <Edit title="Edit single entry" actions={<EditActions />} {...props}>
      <UserEditForm permissions={props.permissions} />
    </Edit>
  );
};

export const PayrollCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput autoFocus source="phoneNumber" validate={required()} label="User Identifier"/>
        <TextInput source="clientNote" />
        <TextInput source="rule" validate={required()} />
        <NumberInput source="payroll" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
