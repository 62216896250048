import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  NumberField,
  EditButton,
  Button,
  TextInput,
  DateInput,
  Title,
  EditActions,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  NumberInput,
  SearchInput,
  ShowButton,
} from "react-admin";
import BookIcon from "@material-ui/icons/Book";
export const PostIcon = BookIcon;

const userFilter = [
  <TextInput source="phoneNumber" label="User Identifier"/>,
  // <DateInput
  //   source="date"
  //   // format={(record: Date) => {
  //   //   if (!(record instanceof Date)) return;
  //   //   console.log("--->  ", record);
  //   //   return record.getTime();
  //   // }}
  // />,
];

const CustomButton = ({ record }: { record: any }) => (
  <ShowButton
    record={record}
    to={`/advance?displayedFilters={"accountId":true}&filter={"accountId":${record.id}}&order=DESC&page=1&perPage=10&sort=date`}
    label="Advances"
  />
);

export const UserList = (props: any) => {
  return (
    <List {...props} filters={userFilter}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="phoneNumber" label="User Identifier"/>
        <TextField source="clientType" />
        <DateField source="lastSeenAt" locales="en-GB" />
        {/* <NumberField source="removed" /> */}
        <CustomButton {...props.record} />
      </Datagrid>
    </List>
  );
};

const UserEditToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} onClick={() => {}} />
      <DeleteWithConfirmButton onClick={() => {}} onFailure={(error) => {}} />
    </Toolbar>
  );
};

const UserEditForm = (props: any) => {
  const newSave = (values: any) => props.save(values);

  return (
    <SimpleForm
      defaultValue={{ role: "user" }}
      toolbar={<UserEditToolbar />}
      {...props}
      save={newSave}
    >
      <NumberInput source="id" validate={required()} disabled />
      <TextInput source="phoneNumber" label="User Identifier"/>
      <TextInput source="clientType" />
      <DateInput source="lastSeenAt" locales="en-GB" />
      <NumberInput source="removed" />
    </SimpleForm>
  );
};

export const UserEdit = (props: any) => {
  return (
    <Edit title="Edit single entry" actions={<EditActions />} {...props}>
      <UserEditForm permissions={props.permissions} />
    </Edit>
  );
};
