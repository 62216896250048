import React from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import authProvider from "./authProvider";
import dataProviderRefactored from "./dataProviderRefactored";

import {
  PayrollList,
  PayrollEdit,
  PayrollCreate,
} from "./views/payrolls/payroll";
import {
  AdvanceList,
  AdvanceEdit,
  AdvanceCreate,
} from "./views/advances/advance";
import { UserList, UserEdit } from "./views/users/user";
import Person from "@material-ui/icons/Person";
import Payment from "@material-ui/icons/Payment";

function App() {
  return (
    <div className="App">
      <Admin dataProvider={dataProviderRefactored} authProvider={authProvider}>
        <Resource
          name="payroll"
          list={PayrollList}
          edit={PayrollEdit}
          create={PayrollCreate}
          icon={Payment}
        />
        <Resource
          name="advance"
          list={AdvanceList}
          edit={AdvanceEdit}
          // create={AdvanceCreate}
        />
        <Resource
          name="user"
          list={UserList}
          edit={UserEdit}
          // create={UserCreate}
          icon={Person}
        />
      </Admin>
    </div>
  );
}

export default App;
